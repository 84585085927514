'use client'

import { Provider } from 'jotai'
import { jotaiStore } from "@/state/jotai/jotaiStore.ts";
// import { DevTools } from "jotai-devtools";
// import 'jotai-devtools/styles.css'

export default function JotaiProvider(
  {
    children,
  }: {
    children: React.ReactNode
  }): React.ReactNode {

  return <Provider store={jotaiStore}>
    {children}
    {/*<DevTools />*/}

  </Provider>
}