'use client'

import { CookiesProvider as ReactCookiesProvider } from "react-cookie";

export default function CookiesProvider(
  {
    children,
  }: {
    children: React.ReactNode
  }): React.ReactNode {
  return <>
    <ReactCookiesProvider>
      {children}
    </ReactCookiesProvider>
  </>
}
