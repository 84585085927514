'use client'

import React, { useEffect, useState } from "react";
import { appTheme } from "@/theme/theme";
import { LicenseInfo } from '@mui/x-license-pro';
import { useMediaQuery } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useThemeMode from "@/common/hooks/useThemeMode.ts";

export default function ThemeProvider(
  {
    muiLicenceKey,
    children,
  }: {
    muiLicenceKey: string
    children: React.ReactNode
  }): React.ReactNode {

  LicenseInfo.setLicenseKey(muiLicenceKey);

  const [themeMode, setThemeMode] = useThemeMode();
  const isDark = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = appTheme(themeMode);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setThemeMode(themeMode);
    setIsMounted(true);
  }, [
    theme,
    isDark,
    themeMode,
    setThemeMode
  ]);

  if (!isMounted) {
    return null;
  }

  return (
    <MuiThemeProvider
      theme={theme}
      defaultMode={themeMode}
    >
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
